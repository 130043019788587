import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { store } from '../store/store';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import Header from '../components/Header';
import FullScreenProject from '../components/FullScreenProject';

const Wrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  max-height: ${({ noScroll }) => noScroll && '100vh'};
  overflow: ${({ noScroll }) => noScroll && 'hidden'};
  background: ${({ bgColor }) => bgColor};
  transition: background 0.45s ease-in;
  will-change: background;
`;

const Layout = ({ children }) => {
  const { state, dispatch } = useContext(store);
  const [bgColor, setBgColor] = useState('#000');

  useEffect(() => {
    setTimeout(() => setBgColor('#B2B2B2'), 3400);
  }, []);

  useEffect(() => {
    state.backgroundColor !== bgColor && setBgColor(state.backgroundColor);
  }, [state.backgroundColor]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <>
        <Wrapper noScroll={!state.introIsReady} bgColor={bgColor}>
          {children}
        </Wrapper>
      </>
      {state.activeProject && <FullScreenProject />}
    </ThemeProvider>
  );
};

export default Layout;
